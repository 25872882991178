import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Advertise = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                advertise_title
                advertise_sub_title
                advertise_content
                advertise_button
                advertise_link
                advertise_image {
                    source_url
                }
            }
        }
    }
    `} render={props => (
        <div id="advertise">
            <div className="advertise-banner">
                <div className="advertise-image" style={{backgroundImage: 'url(' + props.wordpressPage.acf.advertise_image.source_url + ')'}}></div>
                <div className="advertise-content">
                    <div className="title">{ props.wordpressPage.acf.advertise_title }</div>
                    <div className="subtitle" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.advertise_sub_title }}></div>
                </div>
            </div>
            <div className="advertise-button">
                <div className="frame">
                    <div className="content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.advertise_content }}></div>
                    <a data-aos="fade-up" href={ props.wordpressPage.acf.advertise_link }>{ props.wordpressPage.acf.advertise_button }</a>
                </div>
            </div>

        </div>
    ) } />
);

export default Advertise;