import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Download = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                download_title
              	deliva_app_title
              	download_content_
              	download_apple_store_link
              	download_apple_store_image {
                  source_url
                }
              	download_android_store_link
              	download_android_store_image {
                  source_url
                }
              	download_available_store
              	download_main_image {
                  source_url
                }
                press_kit_title
                press_kit_sub_title
                logos_title
                logos_link
                logos_icon {
                  source_url
                }
                screenshot_icon {
                  source_url
                }
                screenshot_link
                screenshot_title
                photographs_icon {
                    source_url
                }
                photographs_link
                photographs_title
                articles_icon {
                    source_url
                }
                articles_link
                articles_title
            }
        }
    }
    `} render={props => (
        <div id="download">
            <div className="download-banner"> 
                <div className="download-image" data-aos="zoom-in-up" data-aos-duration="900" data-aos-easing="ease-in-out-back">
                    <img src={ props.wordpressPage.acf.download_main_image.source_url } alt="" />
                </div>
                <div className="download-content">
                    <div className="title" data-aos="fade-up">{ props.wordpressPage.acf.download_title }</div>
                    <div className="subtitle" data-aos="fade-up">{ props.wordpressPage.acf.deliva_app_title }</div>
                    <div className="content" data-aos="fade-up">{ props.wordpressPage.acf.download_content_ }</div>
                    <div className="stores" data-aos="fade-up">
                        {/* <a href={ props.wordpressPage.acf.download_apple_store_link } target="_blank" rel="noopener noreferrer"><img src={ props.wordpressPage.acf.download_apple_store_image.source_url } alt="apple store icon" /></a> */}
                        <a href={ props.wordpressPage.acf.download_android_store_link } target="_blank" rel="noopener noreferrer"><img src={ props.wordpressPage.acf.download_android_store_image.source_url } alt="android store icon" /></a>
                        <div className="available-in"> { props.wordpressPage.acf.download_available_store } </div>
                    </div>
                </div>
            </div>
            <div className="press-kit">
                <div className="frame">
                    <div className="press-kit-title" data-aos="fade-up">{ props.wordpressPage.acf.press_kit_title }</div>
                    <div className="press-kit-subtitle" data-aos="fade-up">{ props.wordpressPage.acf.press_kit_sub_title }</div>
                    <div className="download-kit">
                        <a href={ props.wordpressPage.acf.logos_link } data-aos="fade-up" target="_blank" rel="noopener noreferrer">
                            <div className="download-border">
                                <img src={ props.wordpressPage.acf.logos_icon.source_url } alt="" />
                                <div className="download-title">{ props.wordpressPage.acf.logos_title }</div>
                            </div>
                        </a> 
                        <a href={ props.wordpressPage.acf.screenshot_link } data-aos="fade-up" data-aos-delay="300" target="_blank" rel="noopener noreferrer">
                            <div className="download-border">
                                <img src={ props.wordpressPage.acf.screenshot_icon.source_url } alt="" />
                                <div className="download-title">{ props.wordpressPage.acf.screenshot_title }</div>
                            </div>
                        </a> 
                        <a href={ props.wordpressPage.acf.photographs_link } data-aos="fade-up" data-aos-delay="600" target="_blank" rel="noopener noreferrer">
                            <div className="download-border">
                                <img src={ props.wordpressPage.acf.photographs_icon.source_url } alt="" />
                                <div className="download-title">{ props.wordpressPage.acf.photographs_title }</div>
                            </div>
                        </a> 
                        <a href={ props.wordpressPage.acf.articles_link } data-aos="fade-up" data-aos-delay="900" target="_blank" rel="noopener noreferrer">
                            <div className="download-border">
                                <img src={ props.wordpressPage.acf.articles_icon.source_url } alt="" />
                                <div className="download-title">{ props.wordpressPage.acf.articles_title }</div>
                            </div>
                        </a> 
                    </div>
                </div>
            </div>
        </div>
    ) } />
);

export default Download;