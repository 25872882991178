import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';


const Blog = () => {

    return <StaticQuery query={graphql`
    {   
        wordpressPage(title: { eq: "Home" }){
            acf {
                blog_title
              	blog_heading
                blog_content
                blog_see_more_content
                blog_read_more_content
            }
        }
        allWordpressPost(limit: 4 ){
            edges{
              node{
                id
                excerpt
                wordpress_id
                title
                slug
                featured_media {
                    source_url
                }
              }
            }
          }
    }
    `} render={props => (
        <div id="blog">
            <div className="home-blog">
                <div className="frame">
                    <div className="blog-main">
                        <div className="blog-title main-deliva-title" data-aos="fade-up">{ props.wordpressPage.acf.blog_title }</div>
                        <div className="blog-heading main-deliva-subtitle" data-aos="fade-up">{ props.wordpressPage.acf.blog_heading }</div>
                    </div>
                    <div className="blog-wrapper">
                        {
                            props.allWordpressPost.edges.map((post, index) => (
                            <div className="item" key={post.node.id} data-aos="fade-up" data-aos-delay={ index * 300 }>
                                <div className="item-wrapper">
                                    <img src={ post.node.featured_media.source_url } alt="" />
                                    <div className="content-wrapper">
                                        <div className="post-title" dangerouslySetInnerHTML = {{ __html: post.node.title }}></div>
                                        <div className="post-excerpt" dangerouslySetInnerHTML = {{ __html: post.node.excerpt.length > 125 ? post.node.excerpt.substring(0,125)+"..." : post.node.excerpt  }}></div>
                                        <Link className="read-more" to={ `/post/${post.node.slug}`}>{ props.wordpressPage.acf.blog_read_more_content }</Link>
                                    </div>
                                </div>
                            </div>   
                            ))
                        }
                    </div>
                    {/* <div className="blog-content">
                        { props.wordpressPage.acf.blog_content }
                    </div>
                    <Link to="/blog" className="blog-see-more">
                        { props.wordpressPage.acf.blog_see_more_content }
                    </Link> */}
                </div>
            </div>
        </div>
    ) } />
};

export default Blog;