import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import moment from 'moment';

const About = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                about_title
                about_subtitle
                about_content
                about_rating {
                    source_url
                }
                testimonials_title
            }
        }
        allWordpressWpTestimonials {
            edges {
              node {
                id
                content
                featured_media {
                  source_url
                }
                date
              }
            }
          }
    }
    `} render={props => (
        <div id="about">
            <div className="frame">
                <div className="main-deliva-title" data-aos="fade-up">{ props.wordpressPage.acf.about_title }</div>
                <div className="main-deliva-subtitle" data-aos="fade-up">{ props.wordpressPage.acf.about_subtitle }</div>
                <div className="content" data-aos="fade-up">{ props.wordpressPage.acf.about_content }</div>
                <div className="star" data-aos="fade-up">
                    <img src={ props.wordpressPage.acf.about_rating.source_url } alt="" />
                </div>
                {/* <div className="main-deliva-subtitle">{ props.wordpressPage.acf.testimonials_title }</div>
                <div className="testimonial-list">
                {
                    props.allWordpressWpTestimonials.edges.map(testimonial => (
                    <div className="item" key={testimonial.node.id}>
                        <div className="item-wrapper">
                            <img src={ testimonial.node.featured_media.source_url } alt="" />   
                            <div className="testimonial-content" dangerouslySetInnerHTML = {{ __html: testimonial.node.content }}></div>
                            <div className="date">{ moment(testimonial.node.date).format('MMMM YYYY')}</div>
                        </div>
                    </div>   
                    ))
                }
                </div> */}
            </div>
        </div>
    ) } />
);

export default About;