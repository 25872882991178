import React from 'react';
import { graphql, StaticQuery } from 'gatsby';


const Contact = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                contact_title
                contact_subtitle
            }
        }
        allWordpressWpContact {
            edges {
              node {
                id
                title
                content
                featured_media {
                  source_url
                }
                acf {
                  button_text
                  button_link
                  color
                }
              }
            }
        }
    }
    `} render={props => (
        <div id="contact">
            <div className="frame">
                <div className="main-deliva-title" data-aos="fade-up">{ props.wordpressPage.acf.contact_title }</div>
                <div className="main-deliva-subtitle" data-aos="fade-up">{ props.wordpressPage.acf.contact_subtitle }</div>
                <div className="contact-list">
                {
                    props.allWordpressWpContact.edges.map((contact, index) => (
                    <div className="item" data-aos="fade-up" data-aos-delay={ index * 300 } style={{borderColor : contact.node.acf.color}} key={contact.node.id}>
                        <div className="item-wrapper">
                            <img src={ contact.node.featured_media.source_url } alt="" />   
                            <div className="title">{contact.node.title}</div>
                            <div className="content" dangerouslySetInnerHTML = {{ __html: contact.node.content }}></div>
                            <a className="contact-btn" style={{backgroundColor : contact.node.acf.color}} href={ contact.node.acf.button_link }>{ contact.node.acf.button_text }</a>
                        </div>
                    </div>   
                    ))
                }
                </div>
            </div>
        </div>
    ) } />
);

export default Contact;