import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import Header from "../header"

const OwlCarousel = React.lazy(() =>
    import('react-owl-carousel')
)
const isSSR = typeof window === "undefined"

const Home = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }){
            acf {
                banner_image {
                source_url
                }
                mobile_image {
                source_url
                }
                heading1
                heading2
                apple_store_image {
                    source_url
                }
                apple_store_link
                android_store_image {
                    source_url
                }
                android_store_link
                bottom_heading
                bottom_paragraph
                available_in
                heading
                sub_heading
            }
        }
        allWordpressWpFeatures {
            edges {
              node {
                id
                title
                content
                featured_media {
                  source_url
                }
              }
            }
        }
        allWordpressWpBanners {
            edges {
                node {
                    id
                    featured_media {
                        source_url
                    }
                    acf {
                        heading1
                        heading2
                        banner_bg {
                            source_url
                        }
                    }
                }
           }
        }
    }
    `} render={props => (  
        <div id="home">
            <div>
            <Header />

            {!isSSR && (
            <React.Suspense fallback={<div className="preloader"></div>}>
                <OwlCarousel
                    className="owl-theme"
                    loop
                    dots = { false }
                    autoplay 
                    autoplayTimeout = { 6000 }
                    items = {1}
                    mouseDrag = { true }
                    touchDrag = { true }
                >
                    {
                    props.allWordpressWpBanners.edges.reverse().map((banner, index) => (
                        <div className="item home-banner" key={banner.node.id}>
                            <div className="banner" style={{backgroundImage: 'url(' + banner.node.acf.banner_bg.source_url + ')'}}></div>
                            <div className="mobile-image" data-aos="zoom-in-up" data-aos-duration="900" data-aos-easing="ease-in-out-back" data-aos-offset="-600">
                                <img className="mobile-image-move" src={ banner.node.featured_media.source_url } alt="mobile banner" />                               
                            </div>
                            <div className="frame">
                                <div className="left-heading">
                                    <div className="heading1" data-aos="fade-up">{ banner.node.acf.heading1 }</div>
                                    <div className="heading2" data-aos="fade-up" data-aos-delay="300">{ banner.node.acf.heading2 }</div>
                                    <div className="stores" data-aos="fade-up" data-aos-delay="600">
                                        {/* <a href={ props.wordpressPage.acf.apple_store_link } target="_blank" rel="noopener noreferrer"><img src={ props.wordpressPage.acf.apple_store_image.source_url } alt="apple store icon" /></a> */}
                                        <a href={ props.wordpressPage.acf.android_store_link } target="_blank" rel="noopener noreferrer"><img src={ props.wordpressPage.acf.android_store_image.source_url } alt="android store icon" /></a>
                                        <div className="available-in"> { props.wordpressPage.acf.available_in } </div>
                                    </div>
                                </div>
                                <div id="right-heading" className="right-heading">
                                    <div className="heading">{ props.wordpressPage.acf.bottom_heading }</div>
                                    <div className="paragraph">{ props.wordpressPage.acf.bottom_paragraph }</div>
                                </div>
                            </div>
                        </div>
                    ))  
                    }

                </OwlCarousel>
            </React.Suspense>
            )}

            </div>
            <div className="home-features" id="home-features">
                <div className="frame"> 
                    <div className="main-deliva-title" data-aos="fade-up">{ props.wordpressPage.acf.heading }</div>
                    <div className="main-deliva-subtitle" data-aos="fade-up">{ props.wordpressPage.acf.sub_heading }</div>
                    <div className="features-list">
                    {
                        props.allWordpressWpFeatures.edges.map((feature, index) => (
                        <div className="item" data-aos="fade-up" data-aos-delay={ index * 300 } key={feature.node.id}>
                            <img src={ feature.node.featured_media.source_url } alt="" />   
                            <div className="title">{feature.node.title}</div>
                            <div className="content" dangerouslySetInnerHTML = {{ __html: feature.node.content }}></div>
                        </div>   
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    ) } />
);

export default Home;