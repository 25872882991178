import React, {Component} from 'react'
import Helmet from "react-helmet"
import { withPrefix } from "gatsby" 
import DefaultTemplate from "../templates/default"
import Home from "../components/home/home"
import Advertise from "../components/home/advertise"
import Blog from "../components/home/blog"
import Download from "../components/home/download"
import Contact from "../components/home/contact"
import About from "../components/home/about"
import Footer from "../components/footer"
import favicon from "../images/favicon.ico"
import AOS from 'aos';
import 'aos/dist/aos.css';

class Index extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render() {
        return <div>
                    <Helmet>
                        <title>DELIVA Online and Offline Coupons and Deals NEAR YOU</title>
                        <link rel="icon" href={favicon} />
                        <meta name="p:domain_verify" content="3f75c882a01931735ed94ce3b19e5b5b"/>
                        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js" type="text/javascript" />
                        <script src={withPrefix('studio137.js')} type="text/javascript" />
                    </Helmet>
                    <Home />
                    <Advertise />
                    <Download />
                    <Blog />
                    <Contact />
                    <About />
                    <Footer />
                </div>
    }
}

export default Index
   